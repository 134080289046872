<template>
    <div class="map-wrapper" style="padding: 0; position: relative;">
        <loader-component :show="loaderActive" loader_type="local"></loader-component>
        <div ref="mapContainer" :style="`width: ${width}; height: ${height};`"></div>
        <tooltip-component ref="tooltip" trigger="manual" :to-element="tooltipTarget" :theme="tooltipTheme" :arrow="false">
            <icon-component v-if="!!tooltipIcon" v-bind="tooltipIcon"></icon-component>
            <span v-html="tooltipData"></span>
        </tooltip-component>
        <a class="button is-round is-dark is-floating" id="zoom-in-button" v-on:click="zoom('in')" :disabled="currentZoom === realZoomBounds[1]">
            <span class="icon"><i class="fas fa-plus"></i></span>
        </a>
        <a class="button is-round is-dark is-floating" id="zoom-out-button" v-on:click="zoom('out')" :disabled="currentZoom === realZoomBounds[0]">
            <span class="icon"><i class="fas fa-minus"></i></span>
        </a>
    </div>
</template>
<script>
module.exports = {
    props:{
        width:{
            type: String,
            default: '100%',
        },
        height:{
            type: String,
            default: '100vh',
        },
        scrollToZoom:{
            type: Boolean,
            default: false
        },
        loaderActive:{
            type: Boolean,
            default: false
        },
        tooltipData:{
            type: String,
            default: ''
        },
        tooltipIcon:{
            type: [Object, Boolean],
            default: false
        },
        tooltipTarget:{
            default: undefined
        },
        tooltipTheme:{
            default: 'dark'
        },
        drawable:{
            type: Boolean,
            default: false
        },
        defaultZoom:{
            type: Number,
            default: undefined
        },
        zoomBounds:{
            type: Array,
            default: undefined,
            validator: (b)=>{
                return b.length === 2 && b[0] < b[1] && b[0] > 0;
            }
        },
        internal:{
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            map:            undefined,
            currentZoom:    this.realDefaultZoom,
            customMarker:   L.Marker.extend({
                layerId: 'custom-layer-id'
            }),
            customGeoJSON:  L.GeoJSON.extend({
                layerId: 'custom-layer-id'
            }),
            customCircle:   L.Circle.extend({
                layerId: 'custom-layer-id'
            })
        };
    },
    async mounted(){
        this.map = L.map(this.$refs.mapContainer,{
            center:             [52.4082193,16.9335103],
            zoom:               this.realDefaultZoom,
            zoomControl:        false,
            scrollWheelZoom:    this.scrollToZoom,
            minZoom:            this.realZoomBounds[0],
            maxZoom:            this.realZoomBounds[1],
        });
        if(!this.internal){
            L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png', {
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
                subdomains: 'abcd',
                maxNativeZoom: 18,
                maxZoom: this.realZoomBounds[1],
                pmIgnore: !this.drawable
            }).addTo(this.map);
        }

        setTimeout(()=>{
            this.map.invalidateSize()
            setTimeout(()=>{
                this.map.on('resize', this.newPosition);
                this.map.on('zoomend', ()=>{
                    this.newPosition();
                    this.currentZoom = this.map.getZoom();
                });
                this.map.on('move', this.newPosition);
                this.map.on('moveend', this.newPosition);
            },0);
        },0);
        
        this.$emit('ready');
    },
    methods:{
        newPosition: function(){
            this.$emit('position');
        },

        zoom: function(dir){
            if(dir=='in'){
                this.map.setZoom(this.map.getZoom() + 1);
            }
            else{
                this.map.setZoom(this.map.getZoom() - 1);
            }
        },

        getCurrentViewBounds: function(pad){
            var bounds = this.map.getBounds();
            if(typeof pad !== 'undefined'){
                bounds = bounds.pad(pad);
            }
            return {
                lat_ge: bounds.getSouth(),
                lat_le: bounds.getNorth(),
                lng_ge: bounds.getWest(),
                lng_le: bounds.getEast(),
            }
        },

        getCurrentView(){
            return {
                center: this.map.getCenter(),
                zoom: this.map.getZoom()
            }
        },

        setCurrentView(new_view, animate = false){
            this.map.setView(new_view.center, new_view.zoom, {animate});
        },

        drawGeoJSON(id, geoJSON, mine, layer, url){
            var ret = undefined;
            if(typeof mine === 'undefined'){
                mine = true;
            }
            if(getIfIsset(()=>geoJSON.properties.type,'') === 'Circle'){
                ret = new this.customCircle(geoJSON.properties.center, {radius: geoJSON.properties.radius, className: 'is-path-'+(mine?'mine':'foreign'), layerId: id});
            }
            else{
                ret = new this.customGeoJSON(geoJSON, {className: 'is-path-'+(mine?'mine':'foreign'), layerId: id});
            }
            if(typeof layer === 'undefined'){
                ret.addTo(this.map);
            }
            else{
                ret.addTo(layer);
            }
            if(typeof url !== 'undefined'){
                const a = document.createElementNS("http://www.w3.org/2000/svg", "a");
                a.setAttribute('href', url);
                const parent = getIfIsset(()=>getIfIsset(()=>ret._layers[Object.keys(ret._layers)[0]]._path, ret._path).parentNode)
                if(typeof parent !== 'undefined' && !(parent.tagName === 'a' && parent.getAttribute('href') === url)){
                    if(isset(()=>ret._layers)){
                        for(const layer of Object.keys(ret._layers)){
                            a.appendChild(ret._layers[layer]._path);
                        }
                    }
                    else{
                        a.appendChild(ret._path);
                    }
                    parent.appendChild(a);
                }
            }
            return ret;
        },

        getGeoJSON(){
            var location = this.map.pm.getGeomanLayers();
            if(location.length !== 1){
                return false;
            }

            location = location[0];
            if(location.pm.getShape() === 'Circle'){
                var props = {
                    type: 'Circle',
                    center: location.getLatLng(),
                    radius: location.getRadius()
                };
                location = L.PM.Utils.circleToPolygon(location).toGeoJSON();
                location.properties = props;
                return {
                    center_lat: props.center.lat,
                    center_lng: props.center.lng,
                    geometry:   location
                };
            }
            else{
                var center = location.getCenter();
                return {
                    center_lat: center.lat,
                    center_lng: center.lng,
                    geometry:   location.toGeoJSON()
                };
            }
        },

        clearGeoJSONs(){
            for(const layer of this.map.pm.getGeomanLayers()){
                layer.remove()
            }
        },

        localToLatLng(localPoint, translationMatrix){
            function destination (latlng, heading, distance) {
                heading = (heading + 360) % 360;
                var rad = Math.PI / 180,
                    radInv = 180 / Math.PI,
                    R = 6378137, // approximation of Earth's radius
                    lon1 = latlng.lng * rad,
                    lat1 = latlng.lat * rad,
                    rheading = heading * rad,
                    sinLat1 = Math.sin(lat1),
                    cosLat1 = Math.cos(lat1),
                    cosDistR = Math.cos(distance / R),
                    sinDistR = Math.sin(distance / R),
                    lat2 = Math.asin(sinLat1 * cosDistR + cosLat1 *
                        sinDistR * Math.cos(rheading)),
                    lon2 = lon1 + Math.atan2(Math.sin(rheading) * sinDistR *
                        cosLat1, cosDistR - sinLat1 * Math.sin(lat2));
                lon2 = lon2 * radInv;
                lon2 = lon2 > 180 ? lon2 - 360 : lon2 < -180 ? lon2 + 360 : lon2;
                return L.latLng([lat2 * radInv, lon2]);
            }
            const referencePoint = L.latLng([translationMatrix[0], translationMatrix[1]])
            const distance = Math.sqrt(localPoint[0]**2 + localPoint[1] ** 2)
            const angle = (Math.atan2(localPoint[1], localPoint[0]) * 180 / Math.PI) + 90
            return destination(referencePoint, angle + translationMatrix[2], distance)
        }
    },
    computed:{
        realDefaultZoom(){
            if(typeof this.defaultZoom !== 'undefined'){
                return this.defaultZoom
            }
            return this.internal ? 18 : 13
        },
        realZoomBounds(){
            if(typeof this.zoomBounds === 'undefined'){
                return this.internal ? [16, 22] : [4, 18]
            }
            return this.zoomBounds
        }
    }
}
</script>
<style scoped>
#zoom-in-button {
    position: absolute;
    right: 5px;
    bottom: 75px;
}

#zoom-out-button {
    position: absolute;
    bottom: 25px;
    right: 5px;
}
</style>