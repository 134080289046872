var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "card", staticClass: "box is-card" },
    [
      _c("loader-component", { attrs: { show: _vm.isLoading } }),
      _vm._v(" "),
      _vm.isResizable || _vm.isMovable
        ? [
            _c(
              "a",
              {
                staticClass: "button is-round is-floating is-dark",
                staticStyle: { display: "none" },
                attrs: { disabled: _vm.disableResize, id: "resize-button" },
                on: { click: _vm.toggleResize },
              },
              [_c("icon-component", { attrs: { icon: _vm.resizeIcon } })],
              1
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "button is-floating is-link",
                attrs: { id: "show-button" },
                on: { click: _vm.toggleMobileShow },
              },
              [_c("icon-component", { attrs: { icon: _vm.mobileShowIcon } })],
              1
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.isClosable
        ? _c(
            "a",
            {
              staticClass: "button is-round is-floating is-danger",
              attrs: { id: "close-button" },
              on: {
                click: function ($event) {
                  return _vm.$emit("close_click")
                },
              },
            },
            [_vm._m(0)]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.$slots.header
        ? _c(
            "h1",
            {
              staticClass: "title",
              on: {
                click: function ($event) {
                  return _vm.$emit("header_click")
                },
              },
            },
            [
              _vm._t("header"),
              _vm._v(" "),
              _c("div", { staticClass: "is-divider" }),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c("transition", { attrs: { name: "slide" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isOpened,
                expression: "isOpened",
              },
            ],
            staticClass: "custom-card-body",
          },
          [_vm._t("default")],
          2
        ),
      ]),
      _vm._v(" "),
      _vm.showMovable
        ? _c("div", {
            staticClass: "moveHandle",
            on: {
              mousedown: (e) => {
                _vm.mousedown(e, "drag")
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.resizeInProgress && _vm.isResizable
        ? _c("div", {
            staticClass: "resizeHandleX left",
            on: {
              mousedown: (e) => {
                _vm.mousedown(e, "left")
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.resizeInProgress && _vm.isResizable
        ? _c("div", {
            staticClass: "resizeHandleX right",
            on: {
              mousedown: (e) => {
                _vm.mousedown(e, "right")
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.resizeInProgress && _vm.isResizable && !_vm.showMovable
        ? _c("div", {
            staticClass: "resizeHandleY top",
            on: {
              mousedown: (e) => {
                _vm.mousedown(e, "top")
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.resizeInProgress && _vm.isResizable
        ? _c("div", {
            staticClass: "resizeHandleY bottom",
            on: {
              mousedown: (e) => {
                _vm.mousedown(e, "bottom")
              },
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fas fa-times" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }