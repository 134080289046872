<template>
    <div ref="card" class="box is-card">
        <loader-component :show="isLoading"></loader-component>
        <template v-if="isResizable||isMovable">
            <a :disabled="disableResize" class="button is-round is-floating is-dark" id="resize-button" v-on:click="toggleResize" style="display: none;">
                <icon-component :icon="resizeIcon"/>
            </a>
            <a class="button is-floating is-link" id="show-button" v-on:click="toggleMobileShow">
                <icon-component :icon="mobileShowIcon"/>
            </a>
        </template>
        <a v-if="isClosable" class="button is-round is-floating is-danger" id="close-button" v-on:click="$emit('close_click')">
            <span class="icon"><i class="fas fa-times"></i></span>
        </a>
        <h1 class="title" v-on:click="$emit('header_click')" v-if="$slots.header">
            <slot name="header"></slot>
            <div class="is-divider"></div>
        </h1>
        <transition name="slide">
            <div class="custom-card-body" v-show="isOpened">
                <slot></slot>
            </div>
        </transition>

        <div v-if="showMovable"                                     class="moveHandle"              @mousedown="(e)=>{mousedown(e,'drag')}"  ></div>
        <div v-if="resizeInProgress && isResizable"                 class="resizeHandleX left"      @mousedown="(e)=>{mousedown(e,'left')}"  ></div>
        <div v-if="resizeInProgress && isResizable"                 class="resizeHandleX right"     @mousedown="(e)=>{mousedown(e,'right')}" ></div>
        <div v-if="resizeInProgress && isResizable && !showMovable" class="resizeHandleY top"       @mousedown="(e)=>{mousedown(e,'top')}"   ></div>
        <div v-if="resizeInProgress && isResizable"                 class="resizeHandleY bottom"    @mousedown="(e)=>{mousedown(e,'bottom')}"></div>
    </div>
</template>
<script>
import loaderComponent from './loader-component.vue';

export default {
    name:'cardComponent',
    props:{
        'is-opened':{
            type:Boolean,
            default:true
        },
        'is-loading':{
            type:Boolean,
            default:false
        },
        'is-closable':{
            type:Boolean,
            default:false
        },
        'is-draggable':{
            type:Boolean,
            default:false
        },
        'is-resizable':{
            type:Boolean,
            default:false
        },
        'is-movable':{
            type:Boolean,
            default:false
        },
        'disable-resize':{
            type:Boolean,
            default:false
        },
    },
    components:{
        loaderComponent
    },
    data(){
        return {
            resizeInProgress:   false,
            mouseInProgress:    false,
            mobileShow:         false,
        }
    },
    methods:{
        toggleResize(){
            if(this.resizeInProgress){
                this.$emit('save-resize');
            }
            else{
                this.$emit('init-resize');
            }
            this.resizeInProgress = !this.resizeInProgress;
        },
        mousedown(event, direction){
            if(this.resizeInProgress){
                this.$emit('mouse-down', event, direction);
                this.mouseInProgress = true;
            }
        },
        toggleMobileShow(){
            this.mobileShow = !this.mobileShow;
            this.$emit('mobile-show', this.mobileShow);
        }
    },
    computed:{
        resizeIcon(){
            if(this.resizeInProgress)
                return 'fas fa-save';
            return 'fas fa-arrows-alt';
        },
        showMovable(){
            return this.resizeInProgress && this.isMovable && !(this.$parent.width==12 && this.$parent.height==12);
        },
        mobileShowIcon(){
            return this.mobileShow?'fas fa-eye-slash':'fas fa-eye';
        }
    }
}
</script>
<style scoped>
.card .loader-container{
    position: absolute;
}

#close-button {
    position: absolute;
    right: 0;
    top: 0;
}

#resize-button {
    position: absolute;
    top: 0;
    right: 45px;
}

.slide-enter-active {
   -moz-transition-duration: 1s;
   -webkit-transition-duration: 1s;
   -o-transition-duration: 1s;
   transition-duration: 1s;
   -moz-transition-timing-function: ease-in;
   -webkit-transition-timing-function: ease-in;
   -o-transition-timing-function: ease-in;
   transition-timing-function: ease-in;
}

.slide-leave-active {
   -moz-transition-duration: 1s;
   -webkit-transition-duration: 1s;
   -o-transition-duration: 1s;
   transition-duration: 1s;
   -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
   max-height: 500px;
   overflow: hidden;
}

.slide-enter, .slide-leave-to {
   overflow: hidden;
   max-height: 0;
}
</style>