<template>
    <input ref="cal" :type="type" />
</template>

<script>
import bulmaCalendar from "./../bulma-calendar/dist/js/bulma-calendar";

export default {
    data() {
        return {
            date: [null, null],
            instance: undefined
        }
    },
    props: {
        clearable: {
            type: Boolean,
            default: false
        },
        dialog: {
            type: Boolean,
            default: false
        },
        inline: {
            type: Boolean,
            default: false
        },
        range: {
            type: Boolean,
            default: false
        },
        options: {
            type: Object,
            default() { return {}; }
        },
        type: {
            type: String,
            default: 'datetime'
        },
        value: {
            type : [Date, Array, Number, String]
        }
    },
    mounted() {
        const vueThis = this
        const globalDefaults = {
            dateFormat: 'dd.MM.yyyy',
            icons: {
                previous:   '<span class="icon"><i class="fas fa-chevron-left"></i></span>',
                next:       '<span class="icon"><i class="fas fa-chevron-right"></i></span>',
                rangeArrow: '<span class="icon"><i class="fas fa-chevron-right"></i></span>',
                time:       '<span class="icon"><i class="far fa-clock"></i></span>',
                date:       '<span class="icon"><i class="far fa-calendar-alt"></i></span>',
                clear:      '<span class="icon"><i class="far fa-trash-alt"></i></span>'
            },
            weekStart: 1
        }
        const globalForced = {
            displayMode:     this.inline ? 'inline' : (this.dialog ? 'dialog' : 'default'),
            isRange:         this.range,
            showClearButton: this.clearable,
            startDate:       this.date[0],
            startTime:       this.date[0],
            endDate:         this.date[1],
            endTime:         this.date[1],
            onReady:         ()=>Vue.nextTick(this.valueChanged)
        }
        // Set date
        if (this.range) {
            if (this.value instanceof Array) {
                this.date = this.value;
            }
        } else {
            this.date[0] = this.value;
        }

        // Attach Calendar
        this.instance = bulmaCalendar.attach(this.$refs.cal, {
            ...globalDefaults,
            ...this.options,
            ...globalForced
        })[0];

        // Event Handler
        this.instance.on('save', e => {
            if(vueThis.type.startsWith('time')){
                vueThis.date = [e.data.startTime.toTimeString().substring(0,5), e.data.endTime.toTimeString().substring(0,5)]
            }
            else{
                vueThis.date = [e.data.startDate, e.data.endDate];
            }
            if (vueThis.range) {
                vueThis.$emit('input', vueThis.date);
            }
            else{
                vueThis.$emit('input', vueThis.date[0]);
            }
        });
    },
    methods:{
        valueChanged(){
            if(this.value === null || typeof this.value === 'undefined'){
                this.instance.clear()
            }
            else if(this.type.startsWith('date')){
                if(this.value instanceof Date){
                    this.instance.value(this.value)
                }
                else{
                    this.instance.value(new Date(this.value))
                }
            }
            else{
                this.instance.value(this.value)
            }
        }
    },
    watch:{
        value:{
            deep: true,
            handler(){
                this.valueChanged()
            }
        }
    }
}
</script>
